import axios from "axios"
import Swal from "sweetalert2"
import { authHeaderInterceptor } from "@uarizona-fnsv/vue-doggo"

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})

api.interceptors.request.use(authHeaderInterceptor)

const alertError = (err) => {
  const message = err.response?.data?.Message || err.message
  Swal.fire({
    title: "Error",
    text: `${message}`,
    icon: "error",
    position: "top-end",
  })
}

export default api
