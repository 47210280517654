<template>
  <v-app>
    <div class="d-flex flex-column">
      <nav class="navbar navbar-expand navbar-dark bg-accent">
        <div class="container" style="max-width: 1172px">
          <div>
            <a
              class="navbar-brand"
              href="http://www.arizona.edu"
              title="The University of Arizona"
            >
              <img
                style="width: 250px"
                alt="The University of Arizona"
                src="https://assets.cdn.fso.arizona.edu/ua_wordmark_line_logo_white_rgb.svg"
              />
            </a>
            <div
              v-if="currentEnvironment.name !== 'Production'"
              :class="`ml-2 badge rounded-pill bg-${currentEnvironment.color}`"
            >
              {{ currentEnvironment.name }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <div v-if="$auth.authorized">
            <v-btn
              v-if="$router.currentRoute.name === 'Admin'"
              dark
              color="#1e5288"
              class="mr-7"
              @click="$router.push('/')"
            >
              Home
            </v-btn>
            <v-btn
              v-else
              dark
              color="#1e5288"
              class="mr-7"
              @click="$router.push('/admin')"
            >
              Admin
            </v-btn>
          </div>

          <v-btn dark color="#1e5288" @click="$auth.logout()">Logout</v-btn>
        </div>
      </nav>
      <router-view></router-view>
    </div>
    <FNSVFooter />
  </v-app>
</template>

<script>
import FNSVFooter from "./components/FNSVFooter"

export default {
  name: "App",
  components: {
    FNSVFooter,
  },

  computed: {
    currentEnvironment() {
      const hostname = window.location.hostname
      if (hostname === "localhost") {
        return { name: "Local", color: "primary" }
      } else if (hostname.match("-dev")) {
        return { name: "Development", color: "primary" }
      } else if (hostname.match("-stage")) {
        return { name: "Stage", color: "warning" }
      } else {
        return { name: "Production", color: "accent" }
      }
    },
  },
}
</script>

<style>
html,
body {
  height: 100%;
}
</style>
